function toggleMenuVisibility(evnt) {
    let style = getComputedStyle(document.getElementById('menu_container'));
    if (style.visibility === 'hidden') {
        document.getElementById('menu_container').style.visibility = 'visible';
    }
    else {
        document.getElementById('menu_container').style.visibility = 'hidden';
    }
}

function hoverMenuItem(parallelogram, evnt) {
    if (!window.matchMedia("(min-width: 430px)").matches) return;
    let highlight = '1px solid black';
    parallelogram.style['z-index'] = '10';
    parallelogram.style['border'] = highlight;
    parallelogram.style['cursor'] = 'pointer';
}

function unhoverMenuItem(parallelogram, evnt) {
    parallelogram.style['z-index'] = '0';
    parallelogram.style['border'] = 'none';
    parallelogram.style['cursor'] = 'auto';
}

// function navigateTo(url, parallelogram, evnt) {
//     unhoverMenuItem(parallelogram);
//     window.location = url;
// }

function toggleCompactMode(x, evnt) {
    // console.log('togglecompactMode');
    // setViewHeightSize();
    if (x.matches) {
        document.getElementById('menu_container').style.visibility = 'visible';
    }
    else {
        document.getElementById('menu_container').style.visibility = 'hidden';
    }
}

function resizeMenuButtonsOutline() {
    if (!window.matchMedia("(min-width: 430px)").matches) return;
    // console.log('resizeMenuButtonsOutline');

    let menuItems = document.getElementsByClassName('menu_item');
    for (let menuItem of menuItems) {
        let parallelogram = document.getElementById(`${menuItem.id}_outline`);
        let menuItemDims = menuItem.getBoundingClientRect();
        // parallelogram.style['top'] = `${menuItemDims['top']}px`;
        parallelogram.style['left'] = `${menuItemDims['left']}px`;
        parallelogram.style['width'] = `${menuItemDims['width']}px`;
        parallelogram.style['height'] = `${menuItemDims['height']}px`;
    }
}

// console.log('Base.js loaded');
document.getElementById('menu_button').addEventListener('click', toggleMenuVisibility);
window.matchMedia("(min-width: 430px)").addEventListener('change', toggleCompactMode);
toggleCompactMode(window.matchMedia("(min-width: 430px)"));

let menuItems = document.getElementsByClassName('menu_item');
let parallelograms = [];
for (let menuItem of menuItems) {
    let parallelogram = document.createElement('div')
    document.body.appendChild(parallelogram);
    parallelograms.push(parallelogram);
    parallelogram.style['position'] = 'absolute';
    parallelogram.style['transform'] = 'skew(-35deg)';
    parallelogram.id = `${menuItem.id}_outline`;

    menuItem.onmouseover = hoverMenuItem.bind(null, parallelogram);
    menuItem.onmouseleave = unhoverMenuItem.bind(null, parallelogram);
    parallelogram.onmouseover = hoverMenuItem.bind(null, parallelogram);
    parallelogram.onmouseleave = unhoverMenuItem.bind(null, parallelogram);
    // parallelogram.onclick = navigateTo.bind(null, menuItem.href, parallelogram);
}

// We listen to the resize event
window.addEventListener('resize', () => {
    setViewHeightSize();
});
setViewHeightSize();

window.addEventListener('resize', () => {
    resizeMenuButtonsOutline();
});
resizeMenuButtonsOutline();

// Set viewport height through javascript, to deal with mobile phone browsers which screw around with apppearing/disappearing toolbars.
// --------------------------------------------------------------------------------------------------------------------
function setViewHeightSize() {
    // console.log('setViewHeightSize');
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
// --------------------------------------------------------------------------------------------------------------------


// Code to display a clickable image in a 'full-screen-pop-up' called the 'modal'
// --------------------------------------------------------------------------------------------------------------------
// Get the modal
let modal = document.getElementById('myModal');
let modalImg = document.getElementById("img01");
let captionText = document.getElementById("caption");

// Get the clickable images and assign callback functionality
let imgs = document.getElementsByClassName('clickable_image');
for (let img of imgs) {
    img.onclick = function(){
        modal.style.display = "block";
        modalImg.src = this.src;
        // use its "alt" text as a caption
        captionText.innerHTML = this.alt;
    }
}

// Get the clickable div background images and assign callback functionality
imgs = document.getElementsByClassName('clickable_div_image');
for (let img of imgs) {
    img.onclick = function(){
        modal.style.display = "block";
        let style = img.currentStyle || window.getComputedStyle(img);
        modalImg.src = style.backgroundImage.slice(4, -1).replace(/['"]/g, "");
        // No caption
        captionText.innerHTML = "";
    }
}

// Get the <span> element that closes the modal
let span = document.getElementsByClassName("close")[0];

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
    modal.style.display = "none";
}
// --------------------------------------------------------------------------------------------------------------------
